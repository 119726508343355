export const apiRoutes = {
  auth: {
    refreshToken: 'auth/refresh-token',
    email: {
      login: 'auth/email/login',
      registrationConfirmation: 'auth/email/registration-confirmation',
      passwordChange: 'auth/email/password-change',
      passwordResetRequest: 'auth/email/password-reset-request',
      passwordReset: 'auth/email/password-reset',
    },
    google: {
      login: 'auth/google/login',
    },
  },
  translations: {
    list: 'translations',
    update: 'translations/:id',
  },
  languages: {
    locale: 'languages/locale/:locale',
    options: 'languages/options',
  },
  users: {
    get: 'users/:id',
    me: 'users/me',
    list: 'users',
    create: 'users',
    update: 'users/:id',
    delete: 'users/:id',
    selfDeleteRequest: 'users/self-delete-request',
    selfDelete: 'users/self-delete/:token',
    bulkDelete: 'users/bulk-delete',
  },
  userTokens: {
    get: 'user-tokens/:token',
  },
  userSettings: {
    updateSelf: 'user-settings/update-self',
    getSelf: 'user-settings/get-self',
    getLayoutOptions: 'user-settings/options/layout',
  },
  profile: {
    updateSelf: 'profile/update-self',
    getSelf: 'profile/get-self',
  },
  roles: {
    options: 'roles/options',
    mappedAccess: 'roles/mapped-access',
  },
  assets: {
    uploadImage: 'assets/upload-image',
    uploadPrivateFile: 'assets/upload-private-file',
    uploadPrivateFiles: 'assets/upload-private-files',
    getAsset: 'assets/owned/:id',
    downloadFile: 'assets/download-file/:id',
    getDownloadableFile: 'assets/get-downloadable-file/:id',
  },
  health: {
    version: 'health/version',
  },
  audit: {
    list: 'audit',
  },
  clients: {
    get: 'clients/:id',
    list: 'clients',
    create: 'clients',
    update: 'clients/:id',
    delete: 'clients/:id',
    options: 'clients/options',
  },
  projects: {
    get: 'projects/:id',
    list: 'projects',
    create: 'projects',
    update: 'projects/:id',
    delete: 'projects/:id',
    statusOptions: 'projects/status-options',
    options: 'projects/options',
  },
  clientRepresentatives: {
    get: 'client-representatives/:id',
    list: 'client-representatives',
    create: 'client-representatives',
    update: 'client-representatives/:id',
    delete: 'client-representatives/:id',
    rolesOptions: 'client-representatives/role-options',
    bulkDelete: 'client-representatives/bulk-delete',
  },
  employees: {
    get: 'employees/:id',
    list: 'employees',
    create: 'employees',
    update: 'employees/:id',
    delete: 'employees/:id',
    rolesOptions: 'employees/role-options',
    typesOptions: 'employees/type-options',
    options: 'employees/options',
    bulkDelete: 'employees/bulk-delete',
  },
  tasks: {
    get: 'tasks/:id',
    kanban: 'tasks/kanban',
    list: 'tasks',
    create: 'tasks',
    update: 'tasks/:id',
    updateMainInfo: 'tasks/:id/main-info',
    updateDetails: 'tasks/:id/details',
    updateAssignees: 'tasks/:id/assignees',
    updateAttachments: 'tasks/:id/attachments',
    updateType: 'tasks/:id/type',
    updateStatus: 'tasks/:id/status',
    updatePriority: 'tasks/:id/priority',
    updateName: 'tasks/:id/name',
    updateDescription: 'tasks/:id/description',
    updateKanbanPosition: 'tasks/:id/position',
    delete: 'tasks/:id',
    typesOptions: 'tasks/type-options',
    prioritiesOptions: 'tasks/priority-options',
    statusOptions: 'tasks/status-options',
    bulkDelete: 'tasks/bulk-delete',
  },
  taskComments: {
    create: 'task-comments',
  },
  taskWorkLogs: {
    create: 'task-work-logs',
  },
};
